import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, ScrollView } from 'react-native';
import styled from 'styled-components';
import { db } from '../../config/firebase';
import { collection, getDocs, doc, deleteDoc, addDoc, setDoc, writeBatch } from 'firebase/firestore';
import { colors } from '../../styles/SharedStyles';
import { GradientButton } from '../GradientButton';

const TabButton = styled.button`
  padding: 10px 20px;
  border: none;
  background: ${props => props.active ? 
    `linear-gradient(0deg, ${colors.primary.start} -14.96%, ${colors.primary.end} 100%)` : 
    'transparent'
  };
  color: ${props => props.active ? 'white' : colors.primary.solid};
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.2s ease;

  &:hover {
    background: ${props => !props.active && 'rgba(0, 0, 0, 0.05)'};
  }
`;

const ComponentForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  font-size: 14px;
`;

const TextArea = styled.textarea`
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  font-size: 14px;
  min-height: 100px;
`;

const ComponentList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 20px;
`;

const ComponentCard = styled.div`
  background: white;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ComponentImage = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 5px;
  margin-bottom: 10px;
`;

const ProductsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 20px;
  padding: 20px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ProductCard = styled.div`
  border: 2px solid ${props => props.selected ? colors.primary.solid : '#eee'};
  border-radius: 8px;
  padding: 15px;
  cursor: pointer;
  transition: all 0.2s ease;
  background: ${props => props.selected ? `${colors.primary.solid}10` : 'white'};
  display: flex;
  flex-direction: column;
  height: 100%;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

const ProductImage = styled.img`
  width: 100%;
  height: 150px;
  object-fit: contain;
  border-radius: 5px;
  margin-bottom: 10px;
  background: white;
  padding: 5px;
  box-sizing: border-box;
`;

const ProductName = styled.h3`
  font-size: 16px;
  margin: 0 0 5px;
  color: ${colors.primary.solid};
`;

const ProductPrice = styled.p`
  font-size: 14px;
  color: #000000;
  margin: 0 0 5px;
  font-weight: bold;
`;

const ProductDescription = styled.p`
  font-size: 12px;
  color: #888;
  margin: 0;
  line-height: 1.4;
  max-height: 120px;
  overflow-y: auto;
  flex: 1;
`;

const Section = styled.div`
  margin-top: 30px;
`;

const SectionTitle = styled.h2`
  font-size: 18px;
  color: ${colors.primary.solid};
  margin-bottom: 15px;
`;

const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const RefreshButton = styled.button`
  padding: 5px 10px;
  background: transparent;
  border: 1px solid ${colors.primary.solid};
  border-radius: 5px;
  color: ${colors.primary.solid};
  font-size: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 5px;

  &:hover {
    background: ${colors.primary.solid}10;
  }
`;

const SystemProductCard = styled(ProductCard)`
  cursor: default;
  
  &:hover {
    transform: none;
  }
`;

const RemoveButton = styled.button`
  width: 100%;
  padding: 8px;
  background: transparent;
  border: 1px solid ${colors.primary.solid};
  border-radius: 5px;
  color: ${colors.primary.solid};
  font-size: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-top: 10px;

  &:hover {
    background: ${colors.primary.solid};
    color: white;
  }
`;

const ProductCardContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 10px;
`;

const ProductCardFooter = styled.div`
  margin-top: auto;
  padding-top: 10px;
`;

const NextButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

const NextButton = styled.button`
  padding: 10px 20px;
  background: linear-gradient(0deg, ${colors.primary.start} -14.96%, ${colors.primary.end} 100%);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

const TabContainer = styled.div`
  flex-direction: row;
  gap: 10px;
  margin-bottom: 20px;
`;

const OrderButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-right: 10px;
`;

const OrderButton = styled.button`
  background: transparent;
  border: 1px solid ${colors.primary.solid};
  color: ${colors.primary.solid};
  width: 30px;
  height: 30px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
  padding: 0;
  font-size: 18px;

  &:hover {
    background: ${colors.primary.solid};
    color: white;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    &:hover {
      background: transparent;
      color: ${colors.primary.solid};
    }
  }
`;

const SaveOrderButton = styled.button`
  background: ${colors.primary.solid};
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }
`;

const ProductCardHeader = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
`;

const Toast = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: ${props => props.type === 'error' ? '#fee' : '#e8f5e9'};
  color: ${props => props.type === 'error' ? '#c00' : '#2e7d32'};
  padding: 15px 30px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  opacity: ${props => props.$isVisible ? 1 : 0};
  visibility: ${props => props.$isVisible ? 'visible' : 'hidden'};
  transition: opacity 0.3s ease, visibility 0.3s ease;
  pointer-events: none;
`;

export const SystemBuilder = () => {
  const [selectedTab, setSelectedTab] = useState('alarms');
  const [components, setComponents] = useState([]);
  const [availableProducts, setAvailableProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [hasOrderChanges, setHasOrderChanges] = useState(false);
  const [savingOrder, setSavingOrder] = useState(false);
  const [toast, setToast] = useState({ show: false, message: '', type: 'success' });

  // Define tab order for navigation
  const tabOrder = ['alarms', 'deterrence', 'cameras', 'poe', 'virtual', 'nvr'];

  const categoryMap = {
    alarms: ['Alarms'],
    deterrence: [], // Empty array means it will accept any product not in the excluded categories
    cameras: ['Cameras'],
    poe: ['Power Over Ethernet (POE)'],
    virtual: ['Virtual Guard'],
    nvr: ['NVR']
  };

  // Add excluded categories for deterrence devices
  const deterrenceExcludedCategories = ['Power Over Ethernet (POE)', 'Virtual Guard', 'NVR', 'Cameras'];

  useEffect(() => {
    fetchExistingComponents();
    fetchProducts();
  }, [selectedTab]);

  const filterProductsByCategory = () => {
    if (selectedTab === 'deterrence') {
      // For deterrence tab, show all products except those in excluded categories
      // and those already in components
      setFilteredProducts(availableProducts.filter(product => 
        !deterrenceExcludedCategories.includes(product.category) &&
        !components.some(component => component.productId === product.id)
      ));
    } else {
      // For other tabs, use the normal category mapping and exclude products
      // that are already in components
      setFilteredProducts(availableProducts.filter(product => 
        categoryMap[selectedTab].includes(product.category) &&
        !components.some(component => component.productId === product.id)
      ));
    }
  };

  useEffect(() => {
    filterProductsByCategory();
  }, [selectedTab, availableProducts]);

  const handleMoveComponent = (index, direction) => {
    const newComponents = [...components];
    const newIndex = direction === 'left' ? index - 1 : index + 1;
    
    // Swap components
    [newComponents[index], newComponents[newIndex]] = [newComponents[newIndex], newComponents[index]];
    
    // Update display order
    const updatedComponents = newComponents.map((component, idx) => ({
      ...component,
      displayOrder: idx
    }));
    
    setComponents(updatedComponents);
    setHasOrderChanges(true); // Set flag for unsaved changes
  };

  const updateComponentsOrder = async (updatedComponents) => {
    try {
      const batch = writeBatch(db);

      updatedComponents.forEach((component) => {
        const componentRef = doc(db, 'systemBuilder', selectedTab, 'components', component.id);
        batch.update(componentRef, { displayOrder: component.displayOrder });
      });

      await batch.commit();
      console.log('Component order updated successfully');
    } catch (error) {
      console.error('Error updating component order:', error);
      setError('Failed to update component order: ' + error.message);
    }
  };

  const fetchExistingComponents = async () => {
    try {
      setLoading(true);
      const componentsSnapshot = await getDocs(collection(db, 'systemBuilder', selectedTab, 'components'));
      const componentsData = componentsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        displayOrder: doc.data().displayOrder || 0
      }));
      
      // Sort components by displayOrder
      const sortedComponents = componentsData.sort((a, b) => a.displayOrder - b.displayOrder);
      setComponents(sortedComponents);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching components:', error);
      setError('Failed to fetch existing components: ' + error.message);
      setLoading(false);
    }
  };

  const fetchProducts = async () => {
    setLoading(true);
    try {
      const querySnapshot = await getDocs(collection(db, 'products'));
      const productsData = querySnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          name: data.name,
          description: data.description,
          price: data.price,
          imageUrl: data.imageUrl,
          category: data.category,
          maxCameras: data.maxCameras || 0,
          installationFee: data.installationFee || 0,
          displayOrder: data.displayOrder || 0
        };
      });
      
      // Sort products by displayOrder
      const sortedProducts = productsData.sort((a, b) => 
        a.displayOrder - b.displayOrder
      );
      
      setAvailableProducts(sortedProducts);
    } catch (err) {
      console.error('Error fetching products:', err);
      setError('Failed to fetch products: ' + err.message);
    }
    setLoading(false);
  };

  const handleProductClick = async (product) => {
    setSelectedProducts(prev => {
      const isSelected = prev.some(p => p.id === product.id);
      if (isSelected) {
        return prev.filter(p => p.id !== product.id);
      } else {
        return [...prev, product];
      }
    });
  };

  const handleAddToSystem = async () => {
    if (selectedProducts.length === 0) return;

    setLoading(true);
    try {
      // Get the highest current displayOrder
      const maxOrder = components.reduce((max, comp) => 
        Math.max(max, comp.displayOrder || 0), -1);

      for (const [index, product] of selectedProducts.entries()) {
        await addDoc(collection(db, 'systemBuilder', selectedTab, 'components'), {
          productId: product.id,
          name: product.name,
          description: product.description,
          price: product.price,
          imageUrl: product.imageUrl,
          maxCameras: selectedTab === 'virtual' || selectedTab === 'poe' || selectedTab === 'nvr' ? product.maxCameras : 0,
          type: selectedTab === 'alarms' ? 'alarm' : 'camera',
          installationFee: product.installationFee || 0,
          category: product.category,
          displayOrder: maxOrder + 1 + index // Add new items at the end
        });
      }

      await fetchExistingComponents();
      await fetchProducts();
      setSelectedProducts([]);
    } catch (error) {
      console.error('Error adding products:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveFromSystem = async (component) => {
    if (window.confirm('Are you sure you want to remove this component from the system?')) {
      try {
        setLoading(true);
        // Delete from the appropriate subcollection
        await deleteDoc(doc(db, 'systemBuilder', selectedTab, 'components', component.id));
        
        // Refresh data
        await fetchExistingComponents();
        await fetchProducts();
        setLoading(false);
      } catch (error) {
        console.error('Error removing component:', error);
        setLoading(false);
      }
    }
  };

  const handleNextTab = () => {
    const currentIndex = tabOrder.indexOf(selectedTab);
    if (currentIndex < tabOrder.length - 1) {
      const nextTab = tabOrder[currentIndex + 1];
      setSelectedTab(nextTab);
    }
  };

  const handleSaveOrder = async () => {
    try {
      setSavingOrder(true);
      await updateComponentsOrder(components);
      setHasOrderChanges(false);
      showToast('Order saved successfully!', 'success');
    } catch (error) {
      console.error('Error saving order:', error);
      showToast('Failed to save order. Please try again.', 'error');
    } finally {
      setSavingOrder(false);
    }
  };

  const showToast = (message, type = 'success') => {
    setToast({ show: true, message, type });
    setTimeout(() => {
      setToast({ show: false, message: '', type });
    }, 3000);
  };

  return (
    <ScrollView style={styles.container}>
      <View>
        <Text style={styles.title}>System Builder</Text>
      </View>

      <TabContainer>
        <TabButton
          active={selectedTab === 'alarms'}
          onClick={() => setSelectedTab('alarms')}
        >
          Alarms
        </TabButton>
        <TabButton
          active={selectedTab === 'deterrence'}
          onClick={() => setSelectedTab('deterrence')}
        >
          Deterrence Devices
        </TabButton>
        <TabButton
          active={selectedTab === 'cameras'}
          onClick={() => setSelectedTab('cameras')}
        >
          Cameras
        </TabButton>
        <TabButton
          active={selectedTab === 'poe'}
          onClick={() => setSelectedTab('poe')}
        >
          POE
        </TabButton>
        <TabButton
          active={selectedTab === 'virtual'}
          onClick={() => setSelectedTab('virtual')}
        >
          Virtual Guard
        </TabButton>
        <TabButton
          active={selectedTab === 'nvr'}
          onClick={() => setSelectedTab('nvr')}
        >
          NVR
        </TabButton>
      </TabContainer>

      {error && (
        <Text style={styles.errorText}>{error}</Text>
      )}

      <Section>
        <SectionHeader>
          <SectionTitle>Current System Components</SectionTitle>
          <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            {hasOrderChanges && (
              <SaveOrderButton 
                onClick={handleSaveOrder}
                disabled={savingOrder}
              >
                {savingOrder ? 'Saving...' : 'Save Order'}
              </SaveOrderButton>
            )}
            <RefreshButton onClick={fetchExistingComponents} disabled={loading}>
              {loading ? 'Refreshing...' : 'Refresh'}
            </RefreshButton>
          </div>
        </SectionHeader>
        <ProductsGrid>
          {components.map((component, index) => (
            <SystemProductCard key={component.id}>
              <ProductCardHeader>
                <OrderButtonsContainer>
                  <OrderButton
                    onClick={() => handleMoveComponent(index, 'left')}
                    disabled={index === 0 || loading}
                  >
                    ←
                  </OrderButton>
                  <OrderButton
                    onClick={() => handleMoveComponent(index, 'right')}
                    disabled={index === components.length - 1 || loading}
                  >
                    →
                  </OrderButton>
                </OrderButtonsContainer>
                <ProductCardContent>
                  <ProductImage 
                    src={component.imageUrl || 'https://via.placeholder.com/150'} 
                    alt={component.name}
                  />
                  <ProductName>{component.name}</ProductName>
                  <ProductPrice>${component.price}</ProductPrice>
                  <ProductDescription>{component.description}</ProductDescription>
                </ProductCardContent>
              </ProductCardHeader>
              <ProductCardFooter>
                <RemoveButton onClick={() => handleRemoveFromSystem(component)}>
                  Remove from System
                </RemoveButton>
              </ProductCardFooter>
            </SystemProductCard>
          ))}
          {components.length === 0 && (
            <Text style={styles.emptyMessage}>
              No components added to the system yet. Select products below to add them.
            </Text>
          )}
        </ProductsGrid>
      </Section>

      <Section>
        <SectionHeader>
          <SectionTitle>Available Products</SectionTitle>
          <RefreshButton onClick={fetchProducts} disabled={loading}>
            {loading ? 'Refreshing...' : 'Refresh'}
          </RefreshButton>
        </SectionHeader>
        <ProductsGrid>
          {filteredProducts.map((product) => (
            <ProductCard 
              key={product.id}
              selected={selectedProducts.some(p => p.id === product.id)}
              onClick={() => handleProductClick(product)}
            >
              <ProductCardContent>
                <ProductImage 
                  src={product.imageUrl || 'https://via.placeholder.com/150'} 
                  alt={product.name}
                />
                <ProductName>{product.name}</ProductName>
                <ProductPrice>${product.price}</ProductPrice>
                <ProductDescription>{product.description}</ProductDescription>
              </ProductCardContent>
            </ProductCard>
          ))}
          {filteredProducts.length === 0 && (
            <Text style={styles.emptyMessage}>
              No products available in this category.
            </Text>
          )}
        </ProductsGrid>
        {selectedProducts.length > 0 && (
          <View style={styles.actionButton}>
            <GradientButton
              text={loading ? 'Adding to System...' : `Add ${selectedProducts.length} Products to System`}
              disabled={loading}
              onPress={handleAddToSystem}
            />
          </View>
        )}
      </Section>

      <NextButtonContainer>
        {selectedTab !== tabOrder[tabOrder.length - 1] && (
          <NextButton onClick={handleNextTab}>
            Next: {tabOrder[tabOrder.indexOf(selectedTab) + 1].charAt(0).toUpperCase() + tabOrder[tabOrder.indexOf(selectedTab) + 1].slice(1)}
            <span>→</span>
          </NextButton>
        )}
      </NextButtonContainer>

      <Toast $isVisible={toast.show} type={toast.type}>
        {toast.message}
      </Toast>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
    color: colors.primary.solid,
  },
  tabs: {
    flexDirection: 'row',
    gap: 10,
    marginBottom: 20,
  },
  actionButton: {
    marginTop: 20,
    alignItems: 'center',
  },
  removeButton: {
    marginTop: 10,
  },
  emptyMessage: {
    gridColumn: '1 / -1',
    textAlign: 'center',
    padding: 20,
    color: colors.gray,
    fontStyle: 'italic',
  },
  errorText: {
    color: colors.error,
    marginBottom: 20,
  },
}); 