import React, { useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import styled from 'styled-components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCart } from '../context/CartContext';
import { colors } from '../styles/SharedStyles';
import { HeaderBar } from '../components/HeaderBar';
import { Footer } from '../components/Footer';
import { isDevelopment } from '../utils/environment';

export default function OrderSuccessPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { clearCart } = useCart();
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hasFetched, setHasFetched] = useState(false);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        if (hasFetched) return;
        
        const sessionId = searchParams.get('session_id');
        if (!sessionId) {
          throw new Error('No session ID found in URL');
        }

        clearCart();
        setHasFetched(true);

        const baseUrl = isDevelopment() 
          ? 'http://localhost:5002/security-central-protection/us-central1'
          : 'https://us-central1-security-central-protection.cloudfunctions.net';

        console.log('Fetching session details from:', baseUrl);
        
        const response = await fetch(`${baseUrl}/getSession?session_id=${sessionId}`);
        
        if (!response.ok) {
          const errorText = await response.text();
          console.error('Session fetch error:', {
            status: response.status,
            statusText: response.statusText,
            body: errorText
          });
          throw new Error('Failed to fetch session details');
        }

        const data = await response.json();
        console.log('Session details received:', data);
        setOrderDetails(data.session);
      } catch (err) {
        console.error('Error fetching order details:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchOrderDetails();
  }, [searchParams, clearCart, hasFetched]);

  if (loading) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
      </LoadingContainer>
    );
  }

  if (error) {
    return (
      <ErrorContainer>
        <ErrorTitle>Error processing order: {error}</ErrorTitle>
        <ErrorText>Please contact support if this issue persists.</ErrorText>
      </ErrorContainer>
    );
  }

  const formatDate = (timestamp) => {
    return new Date(timestamp * 1000).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  return (
    <View style={styles.container}>
      <HeaderBar />
      <SuccessContainer>
        <SuccessContent>
          <SuccessIcon>✓</SuccessIcon>
          <SuccessTitle>Order Successful!</SuccessTitle>
          
          <OrderDetailsSection>
            <OrderDetailRow>
              <DetailLabel>Order ID:</DetailLabel>
              <DetailValue>{orderDetails?.metadata?.shortOrderId || orderDetails?.metadata?.orderId || 'Not available'}</DetailValue>
            </OrderDetailRow>
            
            <OrderDetailRow>
              <DetailLabel>Order Date:</DetailLabel>
              <DetailValue>{formatDate(orderDetails?.created)}</DetailValue>
            </OrderDetailRow>

            <OrderDetailRow>
              <DetailLabel>Total Amount:</DetailLabel>
              <DetailValue>${(orderDetails?.amount_total / 100).toFixed(2)}</DetailValue>
            </OrderDetailRow>

            <Divider />

            <DetailSection>
              <DetailSectionTitle>Delivery Information</DetailSectionTitle>
              <DetailText>
                Method: {orderDetails?.metadata?.deliveryMethod === 'installation' ? 'Professional Installation' :
                        orderDetails?.metadata?.deliveryMethod === 'pickup' ? 'Store Pickup' : 'Shipping'}
              </DetailText>
              {orderDetails?.metadata?.deliveryMethod !== 'pickup' && (
                <AddressDetails>
                  {orderDetails?.metadata?.customerName}<br />
                  {orderDetails?.metadata?.customerAddress}<br />
                  {orderDetails?.metadata?.customerCity}, {orderDetails?.metadata?.customerState} {orderDetails?.metadata?.customerZip}
                </AddressDetails>
              )}
            </DetailSection>

            <DetailSection>
              <DetailSectionTitle>Contact Information</DetailSectionTitle>
              <DetailText>Email: {orderDetails?.metadata?.customerEmail}</DetailText>
              {orderDetails?.metadata?.customerPhone && (
                <DetailText>Phone: {orderDetails?.metadata?.customerPhone}</DetailText>
              )}
            </DetailSection>
          </OrderDetailsSection>

          <SuccessMessage>
            Thank you for choosing Security Central Protection! You will receive an email confirmation shortly.
          </SuccessMessage>

          <ButtonGroup>
            <Button primary onClick={() => navigate('/products')}>
              Continue Shopping
            </Button>
          </ButtonGroup>
        </SuccessContent>
      </SuccessContainer>
      <Footer />
    </View>
  );
}

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60vh;
`;

const LoadingSpinner = styled.div`
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid ${colors.primary.solid};
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const ErrorContainer = styled.div`
  text-align: center;
  padding: 40px 20px;
`;

const ErrorTitle = styled.h5`
  color: #e71c24;
  margin-bottom: 10px;
  font-size: 18px;
`;

const ErrorText = styled.p`
  color: #666;
  font-size: 16px;
`;

const SuccessContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #f5f5f5;
  text-align: center;
`;

const SuccessContent = styled.div`
  background: white;
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
  margin: 20px auto;
`;

const SuccessIcon = styled.div`
  width: 80px;
  height: 80px;
  background: ${colors.primary.solid};
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  margin: 0 auto 20px;
`;

const SuccessTitle = styled.h1`
  font-size: 32px;
  color: ${colors.primary.solid};
  margin-bottom: 30px;
`;

const OrderDetailsSection = styled.div`
  background: #f8f8f8;
  border-radius: 12px;
  padding: 24px;
  margin: 20px 0;
  text-align: left;
`;

const OrderDetailRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #eee;

  &:last-child {
    border-bottom: none;
  }
`;

const DetailLabel = styled.span`
  font-weight: 600;
  color: #666;
`;

const DetailValue = styled.span`
  color: ${colors.primary.solid};
  font-weight: 500;
`;

const Divider = styled.div`
  height: 1px;
  background-color: #eee;
  margin: 20px 0;
`;

const DetailSection = styled.div`
  margin: 20px 0;
`;

const DetailSectionTitle = styled.h3`
  font-size: 18px;
  color: #333;
  margin-bottom: 12px;
`;

const DetailText = styled.p`
  font-size: 14px;
  color: #666;
  margin: 8px 0;
`;

const AddressDetails = styled.div`
  font-size: 14px;
  color: #666;
  margin-top: 8px;
  line-height: 1.5;
`;

const SuccessMessage = styled.p`
  font-size: 16px;
  color: #666;
  margin: 30px 0;
  line-height: 1.5;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

const Button = styled.button`
  padding: 15px 40px;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  
  background: ${props => props.primary ? colors.primary.solid : 'white'};
  color: ${props => props.primary ? 'white' : colors.primary.solid};
  border: ${props => props.primary ? 'none' : `2px solid ${colors.primary.solid}`};

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    minHeight: '100vh',
    backgroundColor: '#f5f5f5',
  },
}); 