const isDevelopment = process.env.NODE_ENV === 'development';
const EMULATOR_HOST = 'http://127.0.0.1:5002/security-central-protection/us-central1';
const PRODUCTION_HOST = 'https://us-central1-security-central-protection.cloudfunctions.net';

export const endpoints = {
  sendContactForm: isDevelopment 
    ? `${EMULATOR_HOST}/sendContactForm`
    : `${PRODUCTION_HOST}/sendContactForm`,
  createCheckoutSession: isDevelopment
    ? `${EMULATOR_HOST}/createCheckoutSession`
    : `${PRODUCTION_HOST}/createCheckoutSession`,
  stripeWebhook: isDevelopment
    ? `${EMULATOR_HOST}/stripeWebhook`
    : `${PRODUCTION_HOST}/stripeWebhook`
}; 